/* You can add global styles to this file, and also import other style files */

/* Some elements here are global ant other are from libs. The lib elements only can be modified in this file */
@import "@angular/cdk/overlay-prebuilt.css";
/* NgxSmartModal */
@import "../node_modules/ngx-smart-modal/ngx-smart-modal.css";

/* MATERIAL ICONS */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(./assets/fonts/material-icons.woff2) format('woff2'),
       url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/roboto.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.green-background {
    background-color: #f4f9f4;
    color: black;
}
.cdk-overlay-pane{
    z-index: 10000 !important;
}
.collapsible-header {
    background-color: #f4f9f4;
}
.collapsible-header span {
    color: black !important;
}
button[type="submit"] {
    background-color: #a7d7c5;
    color: black;
}
button[type="button"] {
    background-color: #a7d7c5;
    color: black;
}
.datepicker-day-button {
    background-color: #fafafa !important;
}

.datepicker-day-button {
    background-color: #fafafa !important;
}

.is-selected button{
    background-color: #a7d7c5 !important;
}

.btn-flat.datepicker-done.waves-effect {
    margin-left: 10px !important;
}

/* Modal */
.nsm-dialog {
    max-width: 2000px !important;
}

.collection {
    overflow: visible !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', "Helvetica Neue", sans-serif; }

.tabs .indicator {
    background-color: #a7d7c5 !important;
}
